import React from "react"
import { Link } from "gatsby"
import './../css/button.css'


export default props =>
<div css={{height:`3rem`, width:`100%`}}>
<Link type = {props.type} to={props.link} className = "button" css={{margin:`0 auto`,textAlign:`center`, verticalAlign:`middle`,textDecoration:`none`,display: `table`,width:`10rem`, backgroundColor:`#7d4aa3`,borderRadius:`20px`, border: `none`,color:`white!important`,fontSize:`normal`, height:`3rem`}}>
<p css={{textAlign:`center`, verticalAlign:`middle`, display: `table-cell`}}> {props.cta}</p>
</Link>
</div>
